<template>
  <footer>
    <nav class="navbar navbar-dark bg-dark">
      <div class="d-flex">
        <a class="navbar-brand" href="/"
          ><img
            class="logo"
            alt="North Texas Bronco Club logo"
            src="../assets/logo.svg"
        /></a>
      </div>
      <div class="copyright">
        <small>© 2022 - North Texas Bronco Club, All rights reserved.</small>
      </div>
    </nav>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logo {
  width: 100%;
  max-width: 160px;
  margin: 0.25rem;
}
footer {
  color: rgba(white, 0.5);
  nav {
    padding: 2rem 1rem;
    background-repeat: repeat;
  }
}
.copyright {
  margin-top: 1rem;
}
</style>
