<template>
  <header>
    <nav
      class="navbar navbar-dark bg-dark navbar-expand justify-content-center"
    >
      <div class="d-flex flex-column justify-content-center">
        <a class="navbar-brand" href="/"
          ><img
            class="logo"
            alt="North Texas Bronco Club logo"
            src="../assets/logo.svg"
        /></a>
        <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
          <li class="nav-item active">
            <a class="nav-link" href="#">Home</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#join">Join</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://shop.northtexasbroncos.org"
              >Shop</a
            >
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: 'Header',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.logo {
  width: 100%;
  max-width: 160px;
  margin: 0.25rem;
}
.nav-item:not(.active) {
  .nav-link {
    color: rgba(255, 255, 255, 0.8);
  }
}
</style>
