import { createApp } from 'vue';
import App from './App.vue';
//import { BootstrapVue } from 'bootstrap-vue';
import store from './store';

// Import Bootstrap an BootstrapVue CSS files (order is important)
// import 'bootstrap/dist/css/bootstrap.css';
// import 'bootstrap-vue/dist/bootstrap-vue.css';

createApp(App).use(store).mount('#app');
