<template>
  <Header />
  <Homepage />
  <Footer />
</template>

<script>
import Homepage from './components/Homepage.vue';
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';

export default {
  name: 'App',
  components: {
    Homepage,
    Header,
    Footer,
  },
};
</script>

<style lang="scss">
#app {
  color: #1b2530;
}
img {
  width: 100%;
}
h1,
h2,
h3,
h4,
h5,
.h1,
.h2,
.h3,
.h4,
.h5 {
  font-family: gineso-condensed, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

p {
  max-width: 35em;
}

article {
  margin: 2rem 0;
}

.container {
  padding: 0 2rem;
}
</style>
