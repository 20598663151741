<template>
  <div class="container text-center">
    <article class="row justify-content-center">
      <h2>Our Mission</h2>
      <p>
        A place for Bronco enthusiasts to share experiences, promote the Bronco
        lifestyle, and do good for the community.
      </p>
    </article>
    <article class="row justify-content-center" id="join">
      <h2>How to Join</h2>
      <div class="col-sm">
        <h4>1. Join the Official Group</h4>
        <div
          class="fb-group"
          data-href="https://www.facebook.com/groups/ntxbroncoclub"
          data-width="280"
          data-show-metadata="true"
        >
          <blockquote
            cite="https://www.facebook.com/groups/ntxbroncoclub"
            class="fb-xfbml-parse-ignore"
          >
            North Texas Bronco Club
          </blockquote>
        </div>
      </div>
      <div class="col-sm">
        <h4>2. Like the Official Page</h4>
        <div
          class="fb-page"
          data-href="https://www.facebook.com/NorthTexasBroncoClub"
          data-tabs=""
          data-width=""
          data-height=""
          data-small-header="true"
          data-adapt-container-width="true"
          data-hide-cover="false"
          data-show-facepile="true"
        >
          <blockquote
            cite="https://www.facebook.com/NorthTexasBroncoClub"
            class="fb-xfbml-parse-ignore"
          >
            <a href="https://www.facebook.com/NorthTexasBroncoClub"
              >North Texas Bronco Club</a
            >
          </blockquote>
        </div>
      </div>
    </article>
    <article class="row justify-content-center">
      <div class="card text-center col-sm-12 col-md-4">
        <div class="card-header">Featured</div>
        <div class="card-body">
          <img src="../assets/badge.png" class="card-img-top" alt="..." />
          <h5 class="card-title h3">Shop is open!</h5>
          <p class="card-text">
            Stickers are available to order now and will start shipping the week of February 14th. Hats, Shirts,
            Stickers, and other accessories will be available for purchase with
            our new graphics in early 2022.
          </p>
          <a
            href="https://shop.northtexasbroncos.org"
            class="btn btn-primary"
            target="_new"
            >Shop Now</a
          >
        </div>
        <div class="card-footer text-muted">Nov. 2021</div>
      </div>
    </article>
  </div>
</template>

<script>
export default {
  name: 'Homepage',
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.row {
  padding: 2rem 0 1rem;
  p {
    max-width: 35em;
  }
}
</style>
